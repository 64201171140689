import config from '../config/headerConfig';

const backgroundWrapper = document.getElementById('background-wrapper');
const quoteWrapper = document.getElementById('quote-wrapper');
const pageIdentifier = document.getElementById('pageIdentifier').innerText;

function setBackground(path) {
  backgroundWrapper.style.backgroundImage = `url(${path})`;
}

function setQuote(quote) {
  if (quoteWrapper) {
    quoteWrapper.innerText = quote;
  }
}

function preloadImages() {
  Object.keys(config[pageIdentifier].backgrounds).forEach(key => {
    const img = new Image();
    const slash = '/';
    img.src = `${window.location.origin}${slash}${config[pageIdentifier].backgrounds[key]}`;
  });
}

export function loopHeader() {
  if (pageIdentifier !== 'battle' && pageIdentifier !== 'kalff') {
    return;
  }
  preloadImages();
  let num = 1;

  window.setInterval(() => {
    if (num > 2) {
      num = 0;
    }

    setBackground(config[pageIdentifier].backgrounds[num]);

    if (pageIdentifier === 'kalff') {
      setQuote(config[pageIdentifier].quotes[num]);
    }

    num += 1;
  }, 6000);
}

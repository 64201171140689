module.exports={
  "kalff": {
    "backgrounds": {
      "0": "dist/images/website/background/kalff/background_01.png",
      "1": "dist/images/website/background/kalff/background_02.png",
      "2": "dist/images/website/background/kalff/background_03.png"
    },
    "quotes": {
      "0": "Werken aan projecten die verschil maken",
      "1": "Ik heb een impact op de wereld met mijn werk",
      "2": "Echt iets goeds doen voor de wereld"
    }
  },
  "battle": {
    "backgrounds": {
      "0": "dist/images/website/background/battle/background_01.png",
      "1": "dist/images/website/background/battle/background_02.png",
      "2": "dist/images/website/background/battle/background_03.png"
    },
    "quotes": {
      "0": "They call me a paperchaser",
      "1": "I am committed",
      "2": "They call me distinguished"
    }
  }
}

import $ from 'jquery';

const pageIdentifier = document.getElementById('pageIdentifier').innerText;

export function collapsables() {
  if (pageIdentifier !== 'battle') {
    return;
  }
  $('#more-info-block').on('hide.bs.collapse', () => {
    $('#more-info-button').html('Ik wil meer weten <i class="fa fa-caret-down"></i>');
  });

  $('#more-info-block').on('show.bs.collapse', () => {
    $('#more-info-button').html('Sluiten <i class="fa fa-caret-up"></i>');
  });
}
